@import "../../styles/color.module.css";


.tile {
    /* Existing styles */
    width: 400px;
    height: fit-content;
    background-color: var(--transparent); /* Assuming white background */
    margin: 20px;
    padding: 10px;
    border-radius: var(--border-radius); 
    border: 1px solid #ccc; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transition: box-shadow 0.3s ease, transform 0.3s ease; 
    color: var(--text-color);
  }
  
  /* Optional: Add a hover effect to enhance the pop effect */
  .tile:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
    transform: translateY(-5px); /* Slight upward movement on hover */
  }