@import "../../styles/color.module.css";


.galleryContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.galleryImage {
  width: 100%;
  height: 144px; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
}