.nlContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
  
  .nlInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 3rem;
    border: 2px solid #ccc;

  }
  
  .nlInput div {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  button[type="button"] {
    padding: 10px;
    font-size: 16px;
    color: var(--white);
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button[type="button"]:hover {
    background-color: #0056b3;
  }
  
  .nlVideos {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
  }
  
  .videoContainer {
    width: 30%;
  }
  
  .videoContainer iframe {
    width: 100%;
    height: 200px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    .nlContainer {
      display: flex;
      flex-direction: column;
    }
    .nlVideos {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      margin-top: 20px;
    }
    .videoContainer {
      width: 100%;
    }
  }
