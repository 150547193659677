@import "../../styles/color.module.css";

.styledButton {
  padding: 10px 20px;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.styledButton:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

.styledButton:active {
  background-color: #003d80;
}
