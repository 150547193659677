@import "../../styles/color.module.css";

.formSheet {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.loginForm {
  padding: 2rem;
  margin: 0 auto; /* Add margin auto to center horizontally */
}

.loginProfile {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 80%;
  background-color: var(--white);
  margin: auto; /* Center the div horizontally */
  height: 70vh; /* Ensure the parent container has a height */
  border-radius: var(--border-radius);

}

.profileHeader {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: #c2c2c2;
  border: 2px solid var(--background-color);
}

.profileName {
}

.profileCircle {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: var(--white); /* Adjust color as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px; /* Adjust font size as needed */
  color: var(--text-color); /* Adjust text color as needed */
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid black;
}

.personalData{
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  gap: 2rem;
}

.profileSection {
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2px solid var(--accent-color);
  margin: 0 3rem;
  align-items: center;
  gap: 0.5rem;
}

.iconRow{
  align-items: start;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.icon{
  height: 100%;
  color: var(--accent-color);
}

.logoutButton{
  width: 80%;
  margin-bottom: 2rem;
  background-color: var(--background-color);
}