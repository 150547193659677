@import "./styles/color.module.css";


.App {
  text-align: left;
  height: 100%;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);

  .content{
    display: flex;
    flex-direction: column;
  }

  .mainContent{
    margin-left: 96px;
    height: 100%;
    min-height: 100vh;
  }
}