/* Importing your color variables */
@import "../../styles/color.module.css";

.navbar {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 20;
  background-color: var(--navbar-primary-color);
}

.navbarActive{
  transition: width 0.5s ease, height 0.5s ease;
  height: 100vh;
}

.navbarClosed{
  height: 0;
  transition: width 0.5s ease, height 0.5s ease;
}

.navContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 80px;
  position: relative;
}

.navLogo {
  color: var(--text-color);
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-items: center;
  transition: color 0.3s, transform 0.3s; /* Smooth transition for color and transform */
}

.navLogo .icon {
  display: inline-block;
  max-width: 80px;
  height: 3rem;
}

.logo {
  height: 6vh;
  transition: transform 0.3s, filter 0.3s; /* Smooth transition for transform and filter */
  cursor: pointer;
}

/* Hover effects for desktop screens only */
@media (min-width: 1024px) { /* Adjust breakpoint as needed */
  .logo {
    height: 80px;
    transition: transform 0.3s, filter 0.3s; /* Smooth transition for transform and filter */
    cursor: pointer;
    z-index: 20;
  }

  .navLogo:hover {
    color: var(--hover-color); /* Change text color on hover */
    transform: scale(1.05); /* Slightly enlarge the container */
  }

  .logo:hover {
    transform: scale(1.1); /* Slightly enlarge the logo on hover */
    filter: brightness(1.2); /* Brighten the logo on hover */
  }

  .navLogo .icon:hover {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
    filter: brightness(1.2); /* Brighten the icon on hover */
  }
}

/* Touch feedback for mobile devices */
@media (hover: none) and (pointer: coarse) {
  
  .logo {
    margin-top: 1rem;
    height: 65px;
    transition: transform 0.3s, filter 0.3s; /* Smooth transition for transform and filter */
    cursor: pointer;
  }

  .navLogo:active {
  }

  .logoActive{
    border: 2px solid var(--active-border-color); /* Add border on touch */
    border-radius:50px;
  }

  .logo:active {
    transform: scale(1.05); /* Slightly enlarge the logo on touch */
    filter: brightness(1.1); /* Brighten the logo on touch */
  }

  .navLogo .icon:active {
    transform: scale(1.05); /* Slightly enlarge the icon on touch */
    filter: brightness(1.1); /* Brighten the icon on touch */
  }
}