@import "../../styles/color.module.css";

.container {
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--text-color);
}

.reviewsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.review {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 5px solid var(--accent-color);
}

.reviewTitle {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--text-color);
}

.reviewBody {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}

.reviewAuthor {
  font-size: 14px;
  text-align: right;
  color: var(--accent-color);
}
