.imgSliderWrapp {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    flex-direction: column;
  }
  
  .container {
    position: relative;
    width: 100vw;
    height: 75vh;
  }
  
  .imgAfterWrap, .imgBeforeWrap {
    position: absolute;
    height: 75vh;
    width: 100vw;
    transition: all 0.15s;
  }
  
  .imgBeforeWrap {
    overflow: hidden;
    width: 100vw;
  }
  
  .imgAfterWrap {
    filter: grayscale(100%);
  }
  
  .image {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
  
  .sliderIndicator {
    position: absolute;
    width: 5px;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #1B2C34;
    z-index: 10; 
    pointer-events: none;
    transition: all 0.15s;
  }
  
  .heroContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    animation: fadeInUp 1.5s forwards 0.5s;
    z-index: 5; 
    pointer-events: none;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translate(-50%, -60%);
    }
  }
  
  .heroContentTest {
    font-size: 2.8em;
    color: #FFF;
    margin-bottom: 20px;
    font-weight: 700;
    pointer-events: none;
    z-index: 40; 
  }
  
  .heroContentTest {
    pointer-events: none;
    z-index: 40; 
  }
  
  .heroBtn {
    padding: 15px 30px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid #FFF;
    color: #FFF;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    bottom: 30%; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
  }
  /* Media query for screens with a width of 280px */
  @media screen and (max-width: 280px) {
    .heroBtn {
      font-size: 1em; 
    }
    .heroContent h1 {
      font-size: 2em; 
    }
  }
  
  /* Media query for screens with a width of 375px */
  @media screen and (max-width: 376px) {
    .heroBtn {
      font-size: 0.9em; 
      bottom: 10%;
    }
    .heroContent h1 {
      font-size: 1.4em; 
    }
  }
  
  /* Media query for screens with a width of 375px */
  @media screen and (max-width: 541px) {
    .heroBtn {
      font-size: 1.1em; 
          bottom: 15%;
    }
    .heroContent h1 {
      font-size: 2.0em;
    }
  }
  
  
  /* Media query for screens with a width of 768px */
  @media screen and (max-width: 768px) {
        .heroBtn {
      font-size: 1.1em; 
          bottom: 18%;
    }
  }
  /* Media query for screens with a width of 768px */
  @media screen and (max-width: 1280px) {
        .heroBtn {
  
          bottom: 18%;
    }
  }
  
  .heroBtn:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .heroBtn:active {
    transform: scale(0.95);
  }
  
  .blankSection {
    height: 80vh;
  }
  