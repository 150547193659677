.about{
    display: flex;
    flex-direction: column;
}

.aboutSection{
    min-height: 33vh;
    height: 33%;
    border-bottom: 1px solid #ccc;
    padding: 2rem;
    margin: 0 2rem
}