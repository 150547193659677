@import "../../styles/color.module.css";
.registerContainer{
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 90%;
}

.registerForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.registerField{
    width: 100%;
}

.registerButton{
    width: 90%;
}

.input{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    width: 100%;
    outline: none;
}