@import "../../styles/color.module.css";

.home {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
  justify-content: center;
  height: 100%;
  color: var(--text-color);
}

.section{
  display: flex;
  flex-direction: row;
}
.imageContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);;
  gap: 2rem;
}

.leftSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 20px;
}

.rightSide {
  flex: 1;
  padding: 20px;
  position: sticky;
  top: 15%;
  height: 100vh; /* Adjust as needed */
}

.mosaicImages{
  border-radius: var(--border-radius);
}

.title {
  font-size: 4rem;
  margin: 0;
}

.subtitle {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.homeButton {
  width: clamp(60%, 100%);
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #f76b1c;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.homeButton:hover {
  background-color: #e0e0e0;
}

.banner{
  height: 6rem;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.companyMission{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem;
}